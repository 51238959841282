<template>
  <div class="shopping-cart">
    <div class="main">
      <div class="cart" v-if="list.length > 0">
        <div class="tab flex-start">
          <div class="item">全部商品({{list.length}})</div>
        </div>
        <div class="head flex-between">
          <div class="item selection"><el-checkbox v-model="selectAll" @change="changeAll">全选</el-checkbox></div>
          <div class="item info">商品</div>
          <div class="item sku">规格</div>
          <div class="item price">单价</div>
          <div class="item count">数量</div>
          <div class="item subtotal">金额</div>
          <div class="item operation">操作</div>
        </div>
        <div class="list">
          <div class="list-item flex-between" v-for="(item, index) in list" :key="index">
            <div class="item selection">
              <el-checkbox v-model="item.isCheck"></el-checkbox>
            </div>
            <div class="item info flex-between">
              <div class="left">
                <img :src="item.goodsPic" alt="">
              </div>
              <div class="right over-hidden2">{{item.goodsName}}</div>
            </div>
            <div class="item sku"></div>
            <div class="item price">￥{{item.price}}</div>
            <div class="item count">
              <el-input-number size="mini" v-model="item.count" :min="1" :max="item.stock" label="描述文字"></el-input-number>
            </div>
            <div class="item subtotal">￥{{item.price * 100 * item.count / 100}}</div>
            <div class="item operation">
              <el-button type="text">移入收藏夹</el-button>
              <el-button type="text" @click="deleteItem(index)">删除</el-button>
            </div>
          </div>
        </div>
        <div class="actions flex-between">
          <div class="left flex-start">
            <div class="selection">
              <el-checkbox v-model="selectAll" @change="changeAll">全选</el-checkbox>
            </div>
            <div class="clear" @click="deleteBatch">删除</div>
            <div class="toFavor">移入收藏夹</div>
          </div>
          <div class="right flex-end">
            <div class="count">已选商品<span>{{selectList.count}}</span>件</div>
            <div class="total">合计:<span>￥{{selectList.total}}</span></div>
            <button class="btn" :class="selectList.count == 0 ? 'forbid' : ''" :disabled="selectList.count == 0">结算</button>
          </div>
        </div>
      </div>
      <div class="noData center" v-else>
        <img src="@/assets/icon/cart_noData.png" alt="">
        <span>购物车内暂时还没有商品</span>
      </div>
      <Browse />
    </div>
  </div>
</template>

<script>
import Browse from "./browse.vue"
export default {
  name: "ShoppingCart",
  components: {
    Browse
  },
  data() {
    return {
      selectAll: false,
      list: [],
      ids: []
    }
  },
  computed: {
    selectList() {
      let total = 0, count = 0;
      this.list.forEach(item => {
        if(item.isCheck) {
          count++;
          total += (item.price * 10000 * item.count) / 10000;
        }
      });
      return {
        count,
        total
      }
    }
  },
  mounted() {
    if(localStorage.cart) {
      let Data = JSON.parse(localStorage.cart);
      Data.forEach(item => {
        item.isCheck = false,
        this.ids.push(item.Id)
      });
      this.list = Data
    }
  },
  methods: {
    // 全选
    changeAll(e) {
      this.list.forEach(item => {
        item.isCheck = e;
      });
    },
    // 删除
    deleteItem(i) {
      this.list.splice(i, 1);
      localStorage.setItem('cart', JSON.stringify(this.list));
    },
    // 批量删除
    deleteBatch() {
      let arr = [];
      this.list.forEach((item) => {
        if(!item.isCheck) {
          arr.push(item)
        }
      });
      this.list = arr;
      localStorage.setItem("cart", JSON.stringify(arr));
    },
  }
}
</script>

<style lang="scss" scoped>
.shopping-cart {
  width: 100%;
  background: #FFFFFF;
  .main {
    width: 1200px;
    margin: 0 auto;
    padding-top: 10px;
    .cart {
      width: 100%;
      .tab {
        width: 100%;
        height: 36px;
        font-size: 16px;
        line-height: 1;
        text-align: center;
        color: #1a3664;
        font-weight: 600;
        .item {
          width: 132px;
          height: 36px;
          border-bottom: 2px solid #1a3664;
        }
      }
      .head {
        width: 100%;
        height: 42px;
        background: #f3f3f3;
        color: #202020;
        font-size: 14px;
        line-height: 42px;
        .item {
          padding: 0 10px;
        }
        .selection {
          width: 100px;
        }
        .info {
          width: 240px;
        }
        .sku {
          width: 230px;
        }
        .price {
          width: 130px;
        }
        .count {
          width: 130px;
        }
        .subtotal {
          width: 140px;
        }
        .operation {
          width: 150px;
        }
      }
      .list {
        width: 100%;
        color: #202020;
        font-size: 14px;
        line-height: 18px;
        .list-item {
          width: 100%;
          .item {
            padding: 20px 10px 0;
          }
          .selection {
            width: 40px;
          }
          .info {
            width: 300px;
            padding: 20px 0 0;
            .left {
              width: 80px;
              height: 80px;
              border: 1px solid #eaeaea;
              img {
                max-width: 80px;
                max-height: 80px;
              }
            }
            .right {
              width: 200px;
              // height: 80px;
              max-height: 36px;
            }
          }
          .sku {
            width: 230px;
          }
          .price {
            width: 130px;
            font-weight: bold;
            color: #202020;
          }
          .count {
            width: 130px;
          }
          .subtotal {
            width: 140px;
            font-weight: bold;
            color: #0AC8F9;
          }
          .operation {
            width: 150px;
          }
        }
      }
      .actions {
        width: 100%;
        height: 50px;
        background: #f3f3f3;
        margin-top: 10px;
        font-size: 14px;
        line-height: 50px;
        .left {
          width: 50%;
          padding: 0 10px;
          .clear {
            margin: 0 20px;
            cursor: default;
            &:hover {
              color: #ff4400;
              text-decoration: underline;
            }
          }
          .toFavor {
            cursor: default;
            &:hover {
              color: #ff4400;
              text-decoration: underline;
            }
          }
        }
        .right {
          width: 50%;
          .count {
            margin-right: 20px;
            span {
              font-weight: bold;
              color: #0AC8F9;
              margin: 0 10px;
            }
          }
          .total {
            margin-right: 20px;
            span {
              font-weight: bold;
              color: #0AC8F9;
              margin: 0 10px;
            }
          }
          .btn {
            width: 120px;
            height: 50px;
            border: none;
            background: #0AC8F9;
            font-size: 16px;
            color: #FFFFFF;
            cursor: pointer;
            letter-spacing: 3px;
          }
        }
      }
    }
    .noData {
      width: 100%;
      height: 300px;
      font-size: 30px;
      font-weight: 400;
      color: #bfbfbf;
      img {
        width: 100px;
        height: 100px;
        margin-right: 20px;
      }
    }
    .browse {
      width: 100%;
    }
  }
}
.forbid {
  background: #b0b0b0 !important;
  cursor: default !important;
}
</style>