<template>
  <div class="browse">
    <div class="youlike-head center">猜你喜欢<img src="@/assets/heart.png" alt="" srcset=""></div>
    <el-carousel>
      <el-carousel-item v-for="(item, index) in likeList" :key="index" indicator-position="outside">
        <div class="list">
          <div class="item" v-for="(e, i) in item" :key="i"  @click="toDetail(e.ItemId)">
            <div class="cover center">
              <img :src="e.MainPic"  alt="">
            </div>
            <div class="bookname over-hidden2"><span>[{{e.SkuList[0].GoodsSource}}]</span>{{e.Title}}{{e.SubTitle}}</div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import {getItemList} from "@/api/common"
export default {
  data() {
    return {
      activeName: "like",
      likeList: []
    }
  },
  mounted() {
    this.getYoulike();
  },
  methods: {
    async getYoulike() {
      let res = await getItemList({
        SmartCode: "guess-like",
        _PageIndex: 1,
        _PageSize: 15
      });
      if(res.IsSuccess) {
        let arr1 = [], arr2 = [], arr3 = [];
        res.Data.forEach((item, i) => {
          if(i < 5) {
            arr1.push(item)
          }else if(i >= 5 && i < 10) {
            arr2.push(item)
          }else {
            arr3.push(item)
          }
        });
        this.likeList = [arr1, arr2, arr3];
      }
    },
    toDetail(id) {
      this.$router.push({
        name: "Detail",
        query: {
          id: id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.browse {
  width: 100%;
  margin-top: 20px;
  border-bottom: 1px solid #eaeaea;
  .youlike-head {
    width: 100%;
    height: 60px;
    background: #F9F9F9;
    font-size: 24px;
    color: #202020;
    padding: 0 40px;
    img {
      width: 25px;
      height: 25px;
      margin-left: 5px;
    }
  }
  .list {
    width: 1028px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding-top: 24px;
    .item {
      width: 178px;
      height: 256px;
      .cover {
        width: 178px;
        height: 178px;
        img {
          max-width: 178px;
          max-height: 178px;
        }
      }
      .bookname {
        width: 100%;
        height: 40px;
        padding: 0 12px;
        margin-top: 12px;
        font-size: 14px;
        font-weight: 400;
        color: #1A3664;
        line-height: 20px;
        span {
          color: #607393;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>